import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useChannelUserFeedStore } from "SmartFeed/stores/feed/channel-user-feed-store";

export const useChannelStore = defineStore("channel-store", {
  state: () => ({
    channels: [],
    channel: {},
    isLoadingCreateChannel: false,
    isLoadingFetchChannel: false,
    isLoadingFetchChannels: false,
  }),
  getters: {
    isChannelUser() {
      return this.channel.is_channel_user;
    },
    channelUsersCount() {
      return this.channel.users_count;
    },
  },
  actions: {
    createChannel(channelName, context, entityId, visibility, successHandler) {
      this.isLoadingCreateChannel = true;
      this.apiPost("/channel", {
        channel_name: channelName,
        context: context,
        entity_id: entityId,
        visibility: visibility,
      }, data => {
        this.channels.push(data);
        successHandler(data);
      }, () => {}, () => {
        this.isLoadingCreateChannel = false;
      });
    },
    fetchChannels(context, entityId, visibility) {
      this.isLoadingFetchChannels = true;
      let apiUrl = `/channels/${context}/${entityId}`;
      if (visibility) {
        apiUrl = `/channels/${context}/${entityId}/${visibility}`;
      }
      this.apiGet(apiUrl, {}, data => {
        this.channels = data;
      }, () => {}, () => {
        this.isLoadingFetchChannels = false;
      });
    },
    fetchChannel(channelId, successHandler = () => {}) {
      this.isLoadingFetchChannel = true;
      this.apiGet(`/channel/${channelId}`,
        {},
        data => {
          this.channel = data;
          this.channelId = channelId;
          successHandler(data);
        },
        () => {},
        () => {
          this.isLoadingFetchChannel = false;
        });
    },
    joinChannel() {
      this.isLoadingJoinChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/${useGeneralStore().userId}`,
        {},
        data => {
          this.channel = data;
          useChannelUserFeedStore().reload();
        },
        () => {},
        () => {
          this.isLoadingJoinChannel = false;
          // reload channel data
        });
    },
    leaveChannel() {
      this.isLoadingJoinChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/remove-self`,
        {},
        data => {
          this.channel = data;
          useChannelUserFeedStore().reload();
        },
        () => {},
        () => {
          this.isLoadingJoinChannel = false;
          // reload channel data
        });
    },
    inviteUserToChannel(userId) {
      this.isLoadingJoinChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/${userId}`,
        {},
        data => {
          this.channel = data;
          useChannelUserFeedStore().reload();
        },
        () => {},
        () => {
          this.isLoadingJoinChannel = false;
          // reload channel data
        });
    },
  },
});
