<template>
  <iColumn
    :max-width="600"
    gap="small"
    width="fill"
    wrap="nowrap"
  >
    <iSpace />
    <iColumn v-if="!replies.length" align="center">
      <iText variant="subtle">
        No replies yet.
      </iText>
    </iColumn>

    <iColumn
      v-for="(reply, index) in replies"
      :key="index"
      :class="{deleted: reply.status === 'inactive'}"
      class="message-container"
      width="fill"
    >
      <ConversationHeader
        :author-avatar="reply.avatarUrl"
        :author-id="reply.userId"
        :author-name="reply.authorName"
        :date="reply.timestampCreated"
      >
        <template #content>
          <MessageFlags
            :moderation-status="reply.moderationStatus"
            :status="reply.status"
            :timestamp-updated="reply.timestampUpdated"
          />
          <iRow>
            <iColumn v-if="isEditing && editingId === reply.messageId">
              <iEditor
                v-if="reply.segments.length"
                :segments="reply.segments"
                hide-send-button
                variant="slack"
                @enter="saveEdit"
                @input="newCommentReply = $event"
              />
              <iEditor
                v-else
                v-model="newCommentReply"
                name="editComment"
                width="fill"
                @enter="saveEdit"
              />
              <iRow align="right">
                <iColumn width="hug">
                  <iButton size="small" @click="saveEdit">
                    Save
                  </iButton>
                </iColumn>
                <iColumn width="hug">
                  <iButton size="small" @click="cancelEditing">
                    Cancel
                  </iButton>
                </iColumn>
              </iRow>
            </iColumn>
            <iColumn v-else>
              <iMessageSegmentsPreview
                v-if="reply.segments.length"
                :segments="reply.segments"
                variant="slack"
              />
              <!-- eslint-disable-next-line -->
              <iText v-else class="message-content" v-html="reply.message"/>
            </iColumn>
          </iRow>
          <iColumn>
            <iText v-if="reply.userId == userId && reply.moderationStatus == 'unknown'" variant="subtle">
              Not visible, pending moderation.
            </iText>
            <iText v-if="reply.userId == userId && reply.moderationStatus == 'blocked'" variant="subtle">
              This message has been blocked.
            </iText>
            <iText v-if="errorMessage" variant="subtle">
              {{ errorMessage }}
            </iText>
            <iRow v-if="isModerator && moderationEnabled" align="between">
              <iText variant="accent">
                Report Count: {{ reply.numReports }}
              </iText>
              <iText variant="accent">
                moderation score: {{ reply.moderationScore }}
              </iText>
            </iRow>
          </iColumn>
          <ConversationActions
            v-if="!isEditing"
            :allow-replying="false"
            :author-id="reply.userId"
            :current-message="reply.message"
            :message-id="reply.messageId"
            :moderation-status="reply.moderationStatus"
            :num-likes="reply.numLikes"
            :num-replies="reply.numApprovedReplies"
            :status="reply.status"
            :user-down-voted="reply.userDownVoted"
            :user-liked="reply.userLiked"
            :user-up-voted="reply.userUpVoted"
            :vote-score="reply.voteScore"
            @approve="approve(reply.messageId)"
            @block="block(reply.messageId)"
            @delete="deleteComment(reply.messageId)"
            @downvote="downVote(reply.messageId)"
            @escalate="escalate(reply.messageId)"
            @like="like(reply.messageId)"
            @report="report(reply)"
            @revokedownvote="revokeDownVote(reply.messageId)"
            @revokeupvote="revokeUpVote(reply.messageId)"
            @undelete="unDeleteComment(reply.messageId)"
            @unlike="unlike(reply.messageId)"
            @upvote="upVote(reply.messageId)"
            @reaction-selected="reactionSelected($event, reply.messageId)"
            @start-editing="turnOnIsEditing(reply.messageId, reply.message)"
          />
          <ConversationFooter
            :author-id="reply.userId"
            :current-message="reply.message"
            :message-id="reply.messageId"
            :moderation-status="reply.moderationStatus"
            :num-replies="reply.numApprovedReplies"
            :reactions="reply.reactions"
            :status="reply.status"
            @reaction-selected="reactionSelected($event, reply.messageId)"
          />
        </template>
      </ConversationHeader>
    </iColumn>
  </iColumn>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import ConversationActions from "%/components/community/chatrooms/slack-style/ConversationActions";
import ConversationHeader from "%/components/community/chatrooms/slack-style/ConversationHeader";
import MessageFlags from "%/components/community/chatrooms/slack-style/MessageFlags";
import { useGeneralStore } from "%/stores/general-store";
import ConversationFooter from "%/components/community/chatrooms/slack-style/ConversationFooter";

export default {
  name: "ReplyWidget",
  components: {
    ConversationFooter,
    ConversationHeader,
    ConversationActions,
    MessageFlags,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editingId: 0,
      isReplying: false,
      newCommentReply: "",
      showMenuOptions: false,
      showReportOptions: false,
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "errorMessage",
      "isModerator",
      "moderationEnabled",
    ]),
    ...mapState(useGeneralStore, ["userId"]),
    isAuthor() {
      return this.userId === this.userId;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      submitSorting: "submitSorting",
      upVoteMessage: "upVoteMessage",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      downVoteMessage: "downVoteMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      unLikeMessage: "unLikeMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
      handleReaction: "handleReaction",
    }),
    turnOnIsEditing(messageId, replyMessage) {
      this.newCommentReply = replyMessage;
      this.editingId = messageId;
      this.isEditing = true;
    },
    turnOnReply() {
      this.fetchRepliesToMessage(this.messageId);
      this.isReplying = true;
    },
    toggleCommentMenu() {
      this.showMenuOptions = true;
    },
    showReportsMenu() {
      this.showMenuOptions = false;
      this.showReportOptions = true;
    },
    saveEdit() {
      if (!this.newCommentReply) {
        return;
      }
      this.isEditing = false;
      this.editMessage(this.editingId, this.newCommentReply);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment(id) {
      this.deleteMessage(id);
      this.showMenuOptions = false;
    },
    unDeleteComment(id) {
      this.undeleteMessage(id);
      this.showMenuOptions = false;
    },
    report(reportData) {
      this.showReportOptions = false;
      this.reportMessage(reportData.id, reportData.reason);
    },
    reactionSelected(reaction, id) {
      this.handleReaction(reaction, id);
    },
    messageReply(newMessage) {
      this.replyToMessage(this.messageId, newMessage);
    },
    like(id) {
      this.likeMessage(id);
    },
    downVote(id) {
      this.downVoteMessage(id);
    },
    upVote(id) {
      this.upVoteMessage(id);
    },
    block(blockData) {
      this.blockMessage(blockData.id, blockData.reason);
    },
    approve(id) {
      this.approveMessage(id);
    },
    escalate(id) {
      this.escalateMessage(id);
    },
    revokeUpVote() {
      this.revokeUpVoteMessage(this.messageId);
    },
    revokeDownVote() {
      this.revokeDownVoteMessage(this.messageId);
    },
    unlike() {
      this.unLikeMessage(this.messageId);
    },
  },
  styleGuide: () => ({
    messageContainerBackground: { "color.background": "light" },
    borderRadius: { "size.borderRadius": "large" },
    actionBackground: { "color.background": "light" },
    actionBorderColor: { "color.border": "dark" },
    actionPadding: { "size.spacing": "small" },
    containerPadding: { "size.spacing": "small" },
  }),
};
</script>

<!-- eslint-disable-next-line -->
<style>
.message-container:has(.i-dropdown .i-dropdown-content) input {
  z-index: -1;
}
</style>
<style lang="scss" scoped>
.card {
  overflow: visible;
}

.message-container {
  position: relative;
  z-index: 0;
  padding-block: v-bind("$getStyles.containerPadding");
}

.message-container:hover {
  z-index: 1;
}

:deep(.i-text p) {
  margin: 0;
}

.message-container:hover {
  background: v-bind("$getStyles.messageContainerBackground");
}

:deep(.message-actions) {
  opacity: 0;
  position: absolute;
  right: 0;
  top: -24px;
  border-radius: v-bind("$getStyles.borderRadius");
  border: 1px solid v-bind("$getStyles.actionBorderColor");
  background: v-bind("$getStyles.actionBackground");
  transition: opacity 0.2s;
  padding: v-bind("$getStyles.actionPadding");
}

:deep(.message-actions:hover) {
  opacity: 1;
}

:deep(.message-actions:has(:deep(.message-dropdown:hover))) {
  opacity: 1;
}

.message-container:hover :deep(.message-actions) {
  opacity: 1;
}

.message-content {
  word-break: break-word;
}
</style>

