<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USERS"
    title="My Users"
    sub-title="Manage your users"
    :breadcrumbs="breadcrumbs"
    page-name="My Users"
    align="center"
    width="full"
    action-button-text="Add User"
    @click:action="openAddUserModal"
  >
    <SmartFeed :pinia-store="useMyPublicationUsersFeedStore" default-entity-layout="tableRow" default-smart-feed-layout="table">
      <template #empty-state>
        <iRow vertical-align="middle" class="empty-state">
          <iIcon icon="person" size="large" />
          <iText>No users yet.</iText>
        </iRow>
      </template>
    </SmartFeed>
  </PageWrapper>
  <iModal
    :visible="showAddUserModal"
    :show-secondary-action-button="false"
    :max-width="550"
    title="Add User"
    @close="closeAddUserModal"
    @click:primary="addPublicationUser"
  >
    <iColumn>
      <iTextInput
        v-model="addUserFirstName"
        label="First Name"
        placeholder="First Name"
        type="text"
        required
      />
      <iTextInput
        v-model="addUserLastName"
        label="Last Name"
        placeholder="last Name"
        type="text"
        required
      />
      <iTextInput
        v-model="addUserEmail"
        label="Email"
        placeholder="Email"
        type="email"
        required
      />
      <iText v-if="addUserError" variant="error">
        {{ addUserError }}
      </iText>
    </iColumn>
  </iModal>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import {
  PAGE_PUBLICATION_USERS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useMyPublicationUsersFeedStore } from "SmartFeed/stores/feed/my-publication-users-feed-store";

export default {
  name: "PublicationUserManagement",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USERS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapWritableState(useCreatorPublicationStore, ["addUserEmail", "addUserFirstName", "addUserLastName", "showAddUserModal"]),
    ...mapState(useCreatorPublicationStore, ["orgId", "publication", "posts", "isLoading", "publicationId", "addUserError"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    useMyPublicationUsersFeedStore,
    ...mapActions(useCreatorPublicationStore, ["addPublicationUser"]),
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    closeAddUserModal() {
      this.showAddUserModal = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
