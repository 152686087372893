<template>
  <iColumn height="fill" gap="none" class="chatroom-thread-container">
    <iRow width="fill" align="right">
      <iChip
        :show-icon="false"
        size="small"
        variant="secondary"
        class="user-count"
        @click="openUsersModal"
      >
        <iText>{{ userCountText }}</iText>
      </iChip>
    </iRow>
    <iRow height="fill" overflow="scroll">
      <ChatroomThread
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
        :description="description"
        :channel-name="channelName"
        :channel-href="channelHref"
      />
    </iRow>
    <iRow v-if="context !== 'channel' || isChannelUser" height="hug">
      <NewChatroomMessage
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
      />
    </iRow>
    <iColumn
      v-else
      width="fill"
      wrap="nowrap"
      vertical-align="middle"
      align="center"
    >
      <iTitle># {{ channelName }}</iTitle>
      <iButton :is-loading="isLoadingJoinChannel" @click="joinChannel">
        Join Channel
      </iButton>
    </iColumn>
  </iColumn>
  <iModal
    :visible="showUsersModal"
    :max-width="600"
    :show-primary-action-button="false"
    secondary-action-text="close"
    title="Users"
    @close="closeUsersModal"
    @click:secondary="closeUsersModal"
  >
    <ManageUsers />
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import NewChatroomMessage from "%/components/community/chatrooms/slack-style/NewChatroomMessage";
import ChatroomThread from "%/components/community/chatrooms/slack-style/ChatroomThread";
import { useChannelStore } from "%/stores/channel-store";
import ManageUsers from "%/components/community/chatrooms/slack-style/ManageUsers.vue";

export default {
  name: "Chatroom",
  components: {
    ChatroomThread,
    NewChatroomMessage,
    ManageUsers,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    channelName: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    channelHref: {
      type: String,
      required: false,
      default: "#",
    },
    userCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showUsersModal: false,
    };
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    ...mapState(useChannelStore, ["channelId", "isChannelUser", "isLoadingJoinChannel"]),
    userCountText() {
      return `${ this.userCount } ${this.userCount == 1 ? "user" : "users" }`;
    },
  },

  methods: {
    ...mapActions(useChannelStore, ["joinChannel"]),
    openUsersModal() {
      this.showUsersModal = true;
    },
    closeUsersModal() {
      this.showUsersModal = false;
    },
  },
  styleGuide: () => ({
    borderTopWidth: { "size.border": "thin" },
    borderTopColor: { "color.border": "standard" },
  }),
};
</script>

<style scoped lang="scss">
  .chatroom {
    width: 100%;
  }
  .user-count {
    cursor: pointer;
  }
</style>
