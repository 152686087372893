import { defineStore } from "pinia";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";
import { links } from "%/enums/link-enums";

export const useGeneralStore = defineStore("general-store", {
  state: () => ({
    appId: null,
    currentPath: "",
    orgId: 0,
    userId: 0,
    userData: {},
    orgData: {},
    links: [],
    baseDomain: "",
    redirectLocation: null,
    isCustomDomain: false,
    adminUserId: 0,
  }),
  getters: {
    fanUrl() {
      return this.links.fanUrl || "";
    },
    isStaging() {
      return window.location.pathname.includes("/staging");
    },
    productionFanUrl() {
      if (this.isStaging) {
        return window.location.protocol + "//" + window.location.host + window.location.pathname.replace("/staging", "");
      }
      return this.fanUrl;
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    isFakeLoggedIn() {
      return this.adminUserId > 0;
    },
    isLoggedIn() {
      return this.userData.user_id && this.userData.user_id > 0;
    },
    settingsBreadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/settings/org/${this.orgId}`, label: "Settings" },
      ];
    },
    getLink: state => linkName => {
      return state.links[linkName];
    },
    userAvatar() {
      if (this.userData.avatar_url) {
        return this.userData.avatar_url;
      }
      return DEFAULT_USER_AVATAR;
    },
    userBio() {
      return this.userData.bio;
    },
    userSplashImage() {
      if (this.userData.splash_image_url) {
        return this.userData.splash_image_url;
      }
      return DEFAULT_SPLASH_IMAGE;
    },
    orgSplash() {
      if (this.orgData.splash_url) {
        return this.orgData.splash_url;
      }
      return DEFAULT_SPLASH_IMAGE;
    },
    orgLogo() {
      if (this.orgData.logo_url) {
        return this.orgData.logo_url;
      }
      return DEFAULT_ORG_LOGO;
    },
    orgCapabilities() {
      return this.orgData.capabilities;
    },
  },
  actions: {
    setAppId(id) {
      this.appId = id;
    },
    setCurrentPath(currentPath) {
      this.currentPath = currentPath;
    },
    setLinks(links) {
      this.links = links;
    },
    setUserId(userId) {
      this.userId = parseInt(userId);
    },
    setOrgData(data) {
      this.orgData = data;
      this.orgId = parseInt(data.org_id);
    },
    setUserData(data) {
      this.userData = data;
    },
    setBaseDomain(baseDomain) {
      this.baseDomain = baseDomain;
    },
    setRedirectLocation(redirectLocation) {
      this.redirectLocation = redirectLocation;
    },
    setIsCustomDomain(isCustomDomain) {
      this.isCustomDomain = isCustomDomain;
    },
    setAdminUserId(adminUserId) {
      this.adminUserId = adminUserId;
    },
    redirectTo(href, fallbackHref = "/") {
      if (!href && !this.redirectLocation) {
        window.location = this.getRelativeHref(fallbackHref);
      } else if (href) {
        window.location = this.getRelativeHref(href);
      } else {
        window.location = this.getRelativeHref(this.redirectLocation);
      }
    },
    getRedirectUrl(href, fallbackHref = "/") {
      let result = "";
      if (!href && !this.redirectLocation) {
        result = this.getRelativeHref(fallbackHref);
      } else if (href) {
        result = this.getRelativeHref(href);
      } else {
        result = this.getRelativeHref(this.redirectLocation);
      }
      return result;
    },
    getRelativeHref(location, redirectLocation) {
      if (!redirectLocation) {
        redirectLocation = this.redirectLocation;
      }
      if ([this.redirectLocation, redirectLocation].includes(location)) {
        redirectLocation = null;
      }
      let url = `${this.links.web}${location}`;
      url = !redirectLocation ? url : `${url}?redirect=${redirectLocation}`;
      return url;
    },
  },
});
