// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-4ed6b10c] {
  width: 100%;
}
.user-count[data-v-4ed6b10c] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/Chatroom.vue","webpack://./Chatroom.vue"],"names":[],"mappings":"AAEE;EACE,WAAA;ACDJ;ADGE;EACE,eAAA;ACAJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .chatroom {\n    width: 100%;\n  }\n  .user-count {\n    cursor: pointer;\n  }\n",".chatroom {\n  width: 100%;\n}\n\n.user-count {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
