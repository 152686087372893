<template>
  <template v-if="isApplicationsEnabled">
    <iColumn width="fill">
      <iSubHeading font-weight="standard">
        We are Looking for Collaborators
      </iSubHeading>

      <iText variant="subtle">
        If you are interested in helping build our digital community we are accepting applications.
        Please submit your application and we will get back to you quickly. We look forward to hearing from you!
      </iText>

      <iRow gap="small" align="left" vertical-align="middle">
        <iButton
          :size="buttonSize"
          :variant="buttonVariant"
          :disabled="shouldDisableButton"
          @click="onApplyClick"
        >
          {{ applyButtonText }}
        </iButton>
        <iText v-if="subtleText" variant="subtle" font-size="small">
          {{ subtleText }}
        </iText>
      </iRow>
    </iColumn>

    <iModal
      v-if="applyToCollaborateModalState"
      title="Apply to Collaborate"
      :max-width="900"
      @close="closeApplyToCollaborateModalAndReset"
    >
      <template #body>
        <iRow v-if="dummyCollaborationSow">
          <iSubHeading>Collaboration</iSubHeading>
        </iRow>
        <iColumn gap="large">
          <iSelect
            v-if="!showRoleText"
            v-model="applyToCollaborateFormData.role"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
            value-field="value"
          />
          <iSubHeading v-else width="fill">
            You are applying to be a {{ preSelectedRole }}
          </iSubHeading>

          <iTextArea
            v-model="applyToCollaborateFormData.message"
            label="Application Message"
            placeholder="Enter your message here..."
            :rows="5"
            width="fill"
          />

          <iText v-if="applyToCollaborateError" variant="error">
            {{ applyToCollaborateError }}
          </iText>
          <CollaborationContractProposal v-if="dummyCollaborationSow" />
        </iColumn>
      </template>
      <template #footer>
        <iRow align="between" gap="standard" width="fill">
          <iColumn width="hug">
            <iButton
              variant="secondary"
              @click="closeApplyToCollaborateModalAndReset"
            >
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              variant="primary"
              loading-spinner-position="inside"
              :is-loading="applyToCollaborateLoadingState"
              :disabled="isDisabled"
              @click="submitApplication"
            >
              Submit Application
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </template>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { useUserStore } from "@/stores/user-store";
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { Enum } from "@bloglovin/vue-component-library";
import CollaborationContractProposal from "@/components/CollaborationContractProposal.vue";
import { useFeatureStore } from "%/stores/feature-store";

export default {
  name: "ApplyToCollaborateProfile",
  components: { CollaborationContractProposal },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    collaboratorRelationship: {
      type: String,
      default: "none",
    },
    isApplicationsEnabled: {
      type: Boolean,
      default: false,
    },
    showExplanationText: {
      type: Boolean,
      default: false,
    },
    showRoleText: {
      type: Boolean,
      default: false,
    },
    defaultRole: {
      type: String,
      default: "",
    },
    buttonSize: {
      type: Enum,
      options: ["small", "standard", "large"],
      default: "standard",
    },
    buttonVariant: {
      type: Enum,
      options: ["primary", "secondary"],
      default: "secondary",
    },
    applyButtonText: {
      type: String,
      default: "Apply Now",
    },
  },
  data() {
    return {
      ROLE_OPTIONS,
      preSelectedRole: this.defaultRole,
    };
  },
  computed: {
    dummyCollaborationSow() {
      return useFeatureStore().isFeatureEnabled("dummyCollaborationSow");
    },
    ...mapState(useUserStore, ["userData", "emailData"]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCollaborationStore, [
      "applyToCollaborateLoadingState",
      "applyToCollaborateModalState",
      "applyToCollaborateFormData",
      "errors",
    ]),
    applyToCollaborateError() {
      return this.errors.applyToCollaborateError;
    },
    isFormValid() {
      return (
        (this.applyToCollaborateFormData.role || this.preSelectedRole)
          && this.applyToCollaborateFormData.message
      );
    },
    isDisabled() {
      return !this.isFormValid || this.applyToCollaborateLoadingState;
    },
    shouldDisableButton() {
      return this.collaboratorRelationship === "owner" ||
          this.collaboratorRelationship === "collaborator" ||
          this.collaboratorRelationship === "applicant" ||
          this.collaboratorRelationship === "applicant_rejected";
    },
    subtleText() {
      switch (this.collaboratorRelationship) {
        case "owner":
          return "You are the owner of this publication";
        case "collaborator":
          return "You are already a collaborator";
        case "applicant":
          return "Your application is pending review";
        case "invited":
          return "You have been invited to collaborate on this publication";
        case "applicant_rejected":
          return "Unfortunately your application was not accepted";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "openApplyToCollaborateModel",
      "closeApplyToCollaborateModalAndReset",
      "applyToCollaborate",
    ]),
    onApplyClick() {
      if (!this.shouldDisableButton) {
        this.openApplyToCollaborateModel();
      }
    },
    async submitApplication() {
      await this.applyToCollaborate(
        this.publicationId,
        this.getOrgData.org_id,
        this.emailData.email_address,
        this.userData.first_name + " " + this.userData.last_name,
        this.applyToCollaborateFormData.role.value || this.preSelectedRole,
        this.orgId,
      );
    },
  },
};
</script>
