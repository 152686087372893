<template>
  <iRow wrap="nowrap">
    <iSpace :width="marginWidth" />
    <iRow :wrap="!$vssMobile ? 'nowrap' : 'wrap'" align="between">
      <iColumn :disabled="isPublishedAndLive" gap="extraSmall">
        <iRow :wrap="!$vssMobile ? 'nowrap' : 'wrap'" vertical-align="middle">
          <iRow
            gap="extraSmall"
            vertical-align="middle"
            width="hug"
            wrap="nowrap"
          >
            <iText variant="subtle">
              Destination:
            </iText>
            <iColumn width="hug">
              <iButton
                v-if="shouldShowAssignToPublicationButton"
                class="text-btn"
                variant="text"
                @click="triggerStoryAssignModal"
              >
                <iText :wrap="false">
                  Assign to platform
                </iText>
              </iButton>
              <iText v-else :wrap="false">
                {{ story.publishing_platform_name }}
              </iText>
            </iColumn>
          </iRow>
          <iColumn width="hug">
            <iButton
              v-if="story.variant_count > 1"
              class="text-btn"
              variant="text"
              @click="showShowStoryVariantsModal = true;"
            >
              +{{ story.variant_count }} variants
            </iButton>
            <iButton
              v-else
              class="text-btn"
              variant="text"
              @click="showCreateVariantsModal = true;"
            >
              No variants
            </iButton>
          </iColumn>
          <iIcon
            v-if="story.publishing_platform_type === PUBLISHING_PLATFORM_TYPE.PUBLICATION"
            :is-loading="updateStoryExclusivityIsLoading"
            :label="isStoryExclusive ? 'Exclusive' : 'Free'"
            :tooltip="exclusiveCheckboxTooltip"
            :variant="isStoryExclusive ? 'branded' : 'subtle'"
            icon="dollar"
            @click="updateStoryExclusivityFlag"
          />
          <iIcon
            v-if="story.publishing_platform_type === PUBLISHING_PLATFORM_TYPE.SYNDICATION_MARKETPLACE"
            :is-loading="updateStoryExclusivityIsLoading"
            :label="isStoryExclusive ? 'Must be exclusive' : 'Can be free'"
            :tooltip="exclusiveCheckboxTooltip"
            :variant="isStoryExclusive ? 'branded' : 'subtle'"
            icon="dollar"
            @click="updateStoryExclusivityFlag"
          />
          <iIcon
            v-if="story.publishing_platform_type === PUBLISHING_PLATFORM_TYPE.SYNDICATION_MARKETPLACE"
            :is-loading="updateStoryRequiresSyndicationApprovalContentIsLoading"
            :variant="isStoryRequiresSyndicationApproval ? 'branded' : 'subtle'"
            icon="lock"
            label="Anyone can publish"
            tooltip="When enabled you will need to approve the syndication of this story."
            @click="updateStoryRequiresSyndicationApprovalFlag"
          />
          <iIcon
            v-if="story.publishing_platform_type === PUBLISHING_PLATFORM_TYPE.SYNDICATION_MARKETPLACE"
            :is-loading="updateStoryAllowRemixContentIsLoading"
            :variant="isStoryAllowRemix ? 'branded' : 'subtle'"
            icon="pencil"
            label="Allow Remix"
            tooltip="When enabled you allow users to modify your story before syndicating it."
            @click="updateStoryAllowRemixFlag"
          />
          <iIcon
            v-if="!isPublishedAndLive"
            :icon="isPreview ? 'visibility' : 'visibility-off'"
            :tooltip="isPreview ? 'Show edit mode' : 'Show preview mode'"
            :variant="isPreview ? 'branded' : 'subtle'"
            @click="isPreview = !isPreview"
          />
        </iRow>
        <iRow vertical-align="middle" wrap="nowrap">
          <iColumn width="hug">
            <iText variant="subtle">
              Tags:
            </iText>
          </iColumn>
          <iHashtagInput
            v-model="hashtags"
            :validation="validateHashtag"
            hashtag-value-key="hashtag"
            @submit:hashtag="addHashtag"
            @delete:hashtag="removeHashtag"
          />
        </iRow>
      </iColumn>
      <iRow align="right" vertical-align="middle">
        <iText
          v-if="!isPublishedAndLive"
          :wrap="false"
          variant="branded"
        >
          {{ savingStatus }}
        </iText>
        <iText
          v-else
          :wrap="false"
          variant="success"
        >
          Published & Live
        </iText>
        <iButton
          v-if="story.status === 'draft'"
          :is-loading="isPublishingStory"
          :loader-on-left="true"
          loading-spinner-position="before"
          size="small"
          @click="publishingStory"
        >
          Publish
        </iButton>
        <iButton
          v-else
          :is-loading="isUnpublishingStory"
          loading-spinner-position="before"
          size="small"
          variant="secondary"
          @click="unpublishStoryAction"
        >
          Edit
        </iButton>
      </iRow>
    </iRow>
    <iSpace :width="marginWidth" />
  </iRow>
  <iEditor
    ref="editor"
    :include-margin="includeMargin"
    :margin-width="marginWidth"
    :preview="isInPreviewMode"
    :segments="segments"
    :upload-post-image="uploadImageFunction"
    :upload-post-video="uploadVideoFunction"
    height="fill"
    overflow="auto"
    variant="builder"
    width="fill"
    @input="saveWithDebounce"
  >
    <template #above-editor>
      <iRow
        :style-overrides="{
          marginTop: '8px',
        }"
        width="fill"
        wrap="nowrap"
      >
        <iTextEditInput
          ref="title"
          v-model="title"
          :min-width="250"
          :disabled="isInPreviewMode"
          placeholder="Untitled Story"
          font-size="extraExtraLarge"
          font-weight="dark"
          editable-on-click
          hide-cta-buttons
          auto-save-on="input"
          hide-borders
          inline
          @submit="saveTitleWithDebounce($event)"
        />
      </iRow>
    </template>
  </iEditor>

  <AssignPublishingPlatformModal
    v-if="assignToPublishingPlatformModalOpen"
    :story-id="story.story_id"
    @change="assignStory"
    @close="assignToPublishingPlatformModalOpen = false; forcePublishAfterAssigning = false"
  />
  <template v-if="story">
    <ShowVariantsModal
      :parent-id="story.variant_parent_id"
      :show="showShowStoryVariantsModal"
      :story-id="story.story_id"
      :story-title="story.title"
      @close="showShowStoryVariantsModal = false"
    />
    <CreateVariantsModal
      :show="showCreateVariantsModal"
      :story-id="story.story_id"
      :story-title="story.title"
      @close="showCreateVariantsModal = false"
    />
    <iModal
      v-if="showEditStoryConfirmation"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :max-width="600"
      :primary-action-button-loading="updateStoryIsLoading"
      primary-action-button-label="Unpublish"
      title="Edit Story"
      @close="closeEditStoryConfirmation"
      @click:primary="forceDraftStatusConfirmation"
      @click:secondary="closeEditStoryConfirmation"
    >
      <template #body>
        <iParagraph>
          Your story is currently live. To edit your story you need to unpublish it. This will remove your story from
          the platforms it is published on until you republish it.
        </iParagraph>
      </template>
    </iModal>
  </template>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { postStatus } from "@/enums/post-enums";
import { getRelativeTimeString, useWindowStore } from "@bloglovin/vue-component-library";
import ShowVariantsModal from "@/components/publication/ShowVariantsModal";
import CreateVariantsModal from "@/components/publication/CreateVariantsModal";
import { useStoryStore } from "%/stores/story-store";
import AssignPublishingPlatformModal from "@/components/publication/AssignPublishingPlatformModal.vue";
import { STORY_VARIANT_TYPES } from "@/constants/story-variant-type-constants";
import { PUBLISHING_PLATFORM_TYPE } from "%/constants/story-constants";

export default {
  name: "StoryEditorComponentDark",
  components: {
    AssignPublishingPlatformModal,
    CreateVariantsModal,
    ShowVariantsModal,
  },
  props: {
    storyData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    saveWhileActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeMargin: {
      type: Boolean,
      required: false,
      default: true,
    },
    marginWidth: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      isStoryExclusive: false,
      isStoryAllowRemix: false,
      isStoryRequiresSyndicationApproval: false,
      showEditStoryConfirmation: false,
      debounce: null,
      storyLastUpdated: null,
      title: "",
      isPreview: false,
      triggerHashtagsModal: false,
      showShowStoryVariantsModal: false,
      showCreateVariantsModal: false,
      initialLoad: 0,
      savingStatus: "Changes Saved",
      forceDraftStatus: false,
      STORY_VARIANT_TYPES,
      PUBLISHING_PLATFORM_TYPE,
      savingStatuses: {
        SAVED: "Changes Saved",
        UNSAVED: "Unsaved Changes",
        SAVING: "...Saving Changes",
        ERROR: "Error Saving Changes",
      },
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
    ...mapState(usePublicationStore, [
      "publicationId",
      "myPublications",
    ]),
    ...mapState(useStoryStore, [
      "uploadStoryImageLoading",
      "loadingAddHashtagToStory",
      "errorAddHashtagToStory",
      "updateStoryExclusivityIsLoading",
      "updateStoryRequiresSyndicationApprovalContentIsLoading",
      "updateStoryAllowRemixContentIsLoading",
      "isPublishingStory",
      "isUnpublishingStory",
    ]),
    ...mapState(useStoryStore, {
      storyStoreData: "story",
    }),
    ...mapWritableState(useStoryStore, [
      "updateStoryIsLoading",
      "assignToPublishingPlatformModalOpen",
      "forcePublishAfterAssigning",
    ]),
    isInPreviewMode() {
      return this.isPublishedAndLive || this.isPreview || this.previewMode;
    },
    isPublishedAndLive() {
      return this.story.status === "active" && this.savingStatus === "Changes Saved" && !this.forceDraftStatus;
    },
    shouldShowAssignToPublicationButton() {
      return this.story.publishing_platform_id == null;
    },
    story() {
      if (this.storyData && typeof this.storyData === "object" && Object.keys(this.storyData).length === 0) {
        return this.storyStoreData;
      }
      return this.storyData;
    },
    segments() {
      const segmentArray = Object.values(this.story.segments);
      return this.story.segment_order.reduce((acc, curr) => {
        const segment = segmentArray.find(seg => seg.story_segment_id === curr.story_segment_id);
        return [
          ...acc,
          segment,
        ];
      }, []);
    },
    exclusiveCheckboxTooltip() {
      return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
    },
    hashtags: {
      get() {
        return this.story.hashtags || [];
      },
      set(value) {
        this.story.hashtags = value;
      },
    },
  },
  mounted() {
    if (this.story.title) {
      this.title = this.story.title || "New Draft Story";
    }
    this.isStoryExclusive = !!this.story.is_exclusive;
    this.isStoryAllowRemix = !!this.story.allow_remix;
    this.isStoryRequiresSyndicationApproval = !!this.story.requires_syndication_approval;
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    ...mapActions(useStoryStore, [
      "uploadStoryImage",
      "uploadStoryVideo",
      "updateStory",
      "addHashtagsToStory",
      "removeHashtagFromStory",
      "updateStoryExclusiveContentFlag",
      "updateStoryAllowRemixContentFlag",
      "updateStoryRequiresSyndicationApprovalContentFlag",
      "updateStoryTitle",
      "assignStoryToPublishingPlatform",
      "publishingStory",
      "triggerStoryAssignModal",
      "unpublishStory",
    ]),
    closeEditStoryConfirmation() {
      this.showEditStoryConfirmation = false;
    },
    forceDraftStatusConfirmation() {
      this.showEditStoryConfirmation = false;
      this.forceDraftStatus = true;
      this.saveTitleWithDebounce(this.title);
    },
    saveWithDebounce(content) {
      this.handleBeforeSave(() => {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = this.savingStatuses.SAVING;
          this.submitStory(content, () => {
            this.setSavingStatusToSaved();
          }, () => {
            this.savingStatus = this.savingStatuses.ERROR;
          });
        }, 1000);
      });
    },
    saveTitleWithDebounce($event) {
      this.handleBeforeSave(() => {
        this.title = $event;
        this.updateStoryIsLoading = true;
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = this.savingStatuses.SAVING;
          this.updateStoryTitle(this.story.story_id, this.title, () => {
            this.setSavingStatusToSaved();
          }, () => {
            this.savingStatus = this.savingStatuses.ERROR;
          });
        }, 1000);
      });
    },
    addHashtag(newTag) {
      this.savingStatus = this.savingStatuses.SAVING;
      this.addHashtagsToStory([newTag], this.story.story_id, () => {
        this.savingStatus = this.savingStatuses.ERROR;
        this.hashtags.pop();
      }, () => {
        this.setSavingStatusToSaved();
      });
    },
    handleBeforeSave(process = () => {
    }) {
      if (this.isPublishedAndLive) {
        return;
      }
      this.savingStatus = this.savingStatuses.UNSAVED;
      process();
    },
    removeHashtag({ el, index }) {
      this.savingStatus = this.savingStatuses.SAVING;
      this.removeHashtagFromStory(el.hashtag_id, this.story.story_id, () => {
        this.savingStatus = this.savingStatuses.ERROR;
        this.hashtags.splice(index, 0, el);
      }, () => {
        this.setSavingStatusToSaved();
      });
    },
    validateHashtag(value) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(value);
      if (!result) {
        return "Hashtag must be alphanumeric and can only contain underscores.";
      }
      return true;
    },
    setSavingStatusToSaved() {
      setTimeout(() => {
        this.savingStatus = this.savingStatuses.SAVED;
      }, 200);
    },
    async uploadImageFunction(file) {
      return await this.uploadStoryImage(file);
    },
    async uploadVideoFunction(file) {
      return await this.uploadStoryVideo(file);
    },
    submitStory(content, onComplete = () => {
    }, onError = () => {
    }) {
      if (this.story.status !== postStatus.ACTIVE) {
        this.updateStoryIsLoading = false;
      }
      this.updateStory(this.story.story_id, content, onComplete, onError);
    },
    updateStoryExclusivityFlag() {
      this.savingStatus = this.savingStatuses.SAVING;
      this.updateStoryExclusiveContentFlag(this.story.story_id, !this.isStoryExclusive, () => {
        this.setSavingStatusToSaved();
        this.isStoryExclusive = !this.isStoryExclusive;
      }, () => {
        this.savingStatus = this.savingStatuses.ERROR;
      });
    },
    updateStoryAllowRemixFlag() {
      this.savingStatus = this.savingStatuses.SAVING;
      this.updateStoryAllowRemixContentFlag(this.story.story_id, !this.isStoryAllowRemix, () => {
        this.setSavingStatusToSaved();
        this.isStoryAllowRemix = !this.isStoryAllowRemix;
      }, () => {
        this.savingStatus = this.savingStatuses.ERROR;
      });
    },
    updateStoryRequiresSyndicationApprovalFlag() {
      this.savingStatus = this.savingStatuses.SAVING;
      this.updateStoryRequiresSyndicationApprovalContentFlag(this.story.story_id,
        !this.isStoryRequiresSyndicationApproval,
        () => {
          this.setSavingStatusToSaved();
          this.isStoryRequiresSyndicationApproval = !this.isStoryRequiresSyndicationApproval;
        },
        () => {
          this.savingStatus = this.savingStatuses.ERROR;
        });
    },
    assignStory(details) {
      this.savingStatus = this.savingStatuses.SAVING;
      this.assignToPublishingPlatformModalOpen = false;

      this.assignStoryToPublishingPlatform(
        this.story.story_id,
        details.publishing_platform_type,
        details.publishing_platform_id,
        () => {
          this.setSavingStatusToSaved();
          this.story.publishing_platform_id = details.publishing_platform_id;
          this.story.publishing_platform_type = details.publishing_platform_type;
          this.story.publishing_platform_name = details.publishing_platform_name;
          if (this.forcePublishAfterAssigning) {
            this.publishingStory();
            this.forcePublishAfterAssigning = false;
          }
        },
        () => {
          this.savingStatus = this.savingStatuses.ERROR;
        },
      );
    },
    async unpublishStoryAction() {
      if (this.story.status === postStatus.ACTIVE && !this.saveWhileActive && !this.forceDraftStatus) {
        this.showEditStoryConfirmation = true;
        return;
      }
      this.unpublishStory(this.story.story_id, true);
    },
    getRelativeTimeString,
  },
};
</script>

<style lang="scss" scoped>
:deep(toptap.ProseMirror) {
  height: 100%;
  box-sizing: border-box;
  cursor: text;
}

.native-story-title {
  margin-top: 4px;

  :deep(.i-text.text-edit) {
    width: 100%;
    font-size: 26px;
    font-weight: 700;
  }
}

.text-btn {
  opacity: 0.5;
}
</style>
