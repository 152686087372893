<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    :page-name="publication.name"
    align="left"
    sub-title=""
    width="full"
  >
    <iColumn gap="standard">
      <ProfileWrapper
        :logo-image="publicationLogo"
        :splash-image="publicationSplashImage"
        :title="publication.name"
      >
        <template #details>
          <iColumn gap="extraSmall" width="hug">
            <iLink :href="domainUrl">
              {{ domainUrl }}
            </iLink>
          </iColumn>
        </template>
        <iStyledCard>
          <template #description>
            <iSpace :height="1" />
            <iColumn gap="extraLarge">
              <ApplyToCollaborateProfile
                :org-id="publication.org_id"
                :publication-id="publication.publication_id"
                :collaborator-relationship="collaboratorRelationship"
                :is-applications-enabled="publication.enable_applications === 'enabled'"
                button-size="small"
                button-variant="primary"
              />
              <iColumn v-if="publication.description" gap="extraSmall">
                <iSubHeading>Description</iSubHeading>
                <iText> {{ publication.description }}</iText>
              </iColumn>
              <iTabs v-model="activeTab" :tabs="tabs">
                <iSpace :height="10" />
                <template #tab(profile)>
                  <iColumn v-if="hasCollaborators" wrap="nowrap">
                    <iSubHeading>
                      Collaborators
                    </iSubHeading>
                    <SmartFeed
                      :pinia-store="usePublicationCollaboratorFeedStore"
                      default-smart-feed-layout="grid"
                      default-entity-layout="cardSmall"
                    />
                  </iColumn>
                </template>
                <template #tab(stories)>
                  <iSpace :height="10" />
                  <SmartFeed
                    default-smart-feed-layout="grid"
                    default-entity-layout="cardSmall"
                    :pinia-store="useCommunityPublicationStoryFeedStore"
                  >
                    <template #empty-state>
                      <iCard width="fill" :style-overrides="{borderWidth: 'none'}">
                        <template #body>
                          <iColumn gap="large" align="center">
                            <iColumn gap="standard" align="center">
                              <iSubHeading variant="subtle">
                                No stories for this publication yet
                              </iSubHeading>
                              <iParagraph
                                variant="subtle"
                                text-align="center"
                                :max-width="400"
                              >
                                Your stories for this publication will appear here.
                              </iParagraph>
                            </iColumn>
                          </iColumn>
                        </template>
                      </iCard>
                    </template>
                  </SmartFeed>
                </template>
              </iTabs>
            </iColumn>
          </template>
        </iStyledCard>
      </ProfileWrapper>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { usePublicationCollaboratorFeedStore } from "SmartFeed/stores/feed/publication-collaborator-feed-store";
import { useOrganizationStore } from "@/stores/organization-store";
import ApplyToCollaborateProfile from "@/components/publication/ApplyToCollaborateProfile.vue";
import { useCommunityPublicationStoryFeedStore } from "SmartFeed/stores/feed/community-publication-story-feed-store";

export default {
  name: "PublicationProfile",
  components: {
    ApplyToCollaborateProfile,
    ProfileWrapper,
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      PAGE_COMMUNITY_COMMUNITY,
      activeTab: "profile",
      tabs: [
        { id: "profile", label: "Profile" },
        { id: "stories", label: "Stories" },
      ],
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "publication",
      "publicationId",
      "publicationSplashImage",
      "publicationLogo",
    ]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCollaborationStore, ["collaborators"]),
    ...mapState(useCreatorPublicationStore, ["collaboratorRelationship"]),
    hasCollaborators() {
      return usePublicationCollaboratorFeedStore().feed.length > 0;
    },
    domainUrl() {
      if (this.publication.domain) {
        if (this.publication.domain.startsWith("http", 0)) {
          return this.publication.domain;
        }
        return "https://" + this.publication.domain;
      }
      return this.internalDomain;
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
  },
  methods: {
    useCommunityPublicationStoryFeedStore,
    usePublicationCollaboratorFeedStore,
  },
};
</script>

<style lang="scss" scoped>
</style>
